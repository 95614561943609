import { useState } from 'react';
import React from 'react';
import logo from '../images/Vector.png';
import '../css/nav.css';
import '../css/global.css'
import { RxHamburgerMenu } from 'react-icons/rx';
import { RxCross2 } from "react-icons/rx"
import { Link } from "react-router-dom"
import { AiFillInstagram } from "react-icons/ai"
import { BsTwitter } from "react-icons/bs"
import { BsLinkedin } from "react-icons/bs"

const Navbar1 = () => {
  const [showMedia, setMedia] = useState(false);

  const handleHamburgerClick = (event) => {
    event.preventDefault();
    setMedia(!showMedia);
    document.body.classList.toggle('menu-open', !showMedia);
  };



  return (
    <>
    <nav className="navbar">

      
        {/* {/ { logo } /} */}
        <div className='logo'>
          <a href="/"><img src={logo} alt="logo" /></a>
        </div>

        {/* {/ { menu } /} */}
        <div className={showMedia ? "menu mobile-menu" : "menu"}>
          <ul>
           
            <li>
              <a href="/blog"> Our Stories </a>
            </li>
            <li>
              <a href="/contact"> Contact Us </a>
            </li>
            <li>
            <button className='button'>  
            <Link to="https://calendly.com/rohit-grouple" target='_blank'>
              <p className='button-text'> Book a demo</p>
            </Link>
          </button>
            </li>

            <div className='logos'>
              <a className="insta" href="https://twitter.com/Grouple_exp" target='blank'> <BsTwitter /> </a>
              <a className="insta" href="https://www.instagram.com/grouple_in/?hl=en" target='blank'> <AiFillInstagram /> </a>
              <a className="insta" href='https://www.linkedin.com/company/grouple/?originalSubdomain=in' target='blank'> <BsLinkedin /> </a>
            </div>

            
          </ul>
         
        </div>



        {/* {/ { hamburger menu } /} */}
        <button className="hamburger" onClick={handleHamburgerClick}>
          {showMedia ? (
            <RxCross2 style={{ color: 'black' }} />
          ) : (
            <RxHamburgerMenu style={{ color: 'black' }} />
          )}
        </button>

      </nav>
    </>
  );
};

export default Navbar1;
