import React from "react";
import "../css/privacypolicy.css";
import NewFooter from "../New Components/Footer";
import NewNavbarTwo from "../New Components/NewNavbarTwo";

const PrivacyPolicy = () => {
  return (
    <>
      <NewNavbarTwo />
      <div className="privacyPolicy">
        <h1>Privacy Policy </h1>
        <h3>Updated on December 16, 2023</h3>

        <div className="inside-privacypolicy">
          <p>
            We prioritize your privacy and are dedicated to safeguarding your
            personal information. This privacy policy outlines how we collect,
            use, disclose, and protect your data when you interact with our
            platform. By accessing and using our platform, you acknowledge and
            consent to the practices described in this privacy policy. Please
            take a moment to review the following information to understand how
            we handle your personal information.
          </p>

          <h2>Information we collect</h2>
          <p>
            We may collect the following types of personal information when you
            use our Service:
          </p>
          <ul>
            <li>
              Contact information, such as your name, email address, phone
              number, and mailing address.
            </li>
            <li>
              Payment details, including credit card information, necessary for
              processing payments for bookings.
            </li>
            <li>
              Company name and phone number if you represent a business entity.
            </li>
            <li>
              IP address and device data, which may include browser type,
              operating system, and other technical information about your
              device.
            </li>
            <li>
              Content that you upload, submit, store, or send through the
              Service.
            </li>
          </ul>

          <h2>Use of information</h2>
          <p>
            We use the personal information collected for the following
            purposes:
          </p>
          <ul>
            <li>
              To provide the Service and facilitate group bookings and
              transactions.
            </li>
            <li>
              To process payments for bookings and comply with financial
              regulations.
            </li>
            <li>
              To communicate with you about your account, updates & related
              issues.
            </li>
            <li>
              To improve and enhance the Service, including new features and
              functionality.
            </li>
            <li>
              To conduct analytics & research for the purposes of improving our
              platform
            </li>
            <li>To respond to your inquiries and customer support requests.</li>
            <li>
              To send you marketing communications, such as newsletters or
              promotional offers, but only if you have opted to receive such
              communications.
            </li>
          </ul>

          <h2>Data security</h2>
          <ul>
            <li>
              Grouple takes reasonable measures to protect your personal
              information from unauthorized access, disclosure, alteration, and
              destruction. However, please note that no method of transmission
              over the internet or electronic storage is 100% secure, and we
              cannot guarantee absolute security.
            </li>
            <li>
              We will retain your personal information for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.
            </li>
          </ul>

          <h2>Data sharing</h2>
          <p>
            We may share your personal information with third parties under the
            following circumstances:
          </p>
          <ul>
            <li>
              With service providers and business partners who assist us in
              providing the Service, such as payment processors, customer
              support providers, and hosting partners.
            </li>
            <li>
              With third parties for marketing purposes if you have provided
              consent. With law enforcement or government authorities when
              required by law or to protect our legal rights and interests.
            </li>
          </ul>

          <h2>Your rights and choices</h2>
          <ul>
            <li>
              You have the right to access, correct, update, or delete your
              personal information. You may also have the right to object to or
              restrict certain processing of your data. If you wish to exercise
              any of these rights, please contact us using the information
              provided at the end of this Privacy Policy.
            </li>
            <li>
              We may update this Privacy Policy from time to time to reflect
              changes to our practices or for other operational, legal, or
              regulatory reasons. The updated Privacy Policy will be posted on
              this page with the revised date. We encourage you to review this
              page regularly for any updates.
            </li>
          </ul>

          <h2>Children privacy</h2>
          <ul>
            <li>
              Our Service is not directed to individuals under the age of 16,
              and we do not knowingly collect personal information from children
              under 16.
            </li>
            <li>
              Changes to this Privacy Policy: We may update this Privacy Policy
              from time to time for operational, legal, or regulatory reasons.
              The updated Policy will be posted on this page, and we encourage
              you to review it regularly for any changes.
            </li>
            <li>
              By using the Grouple Service, you agree to the terms and
              conditions outlined in this Privacy Policy.
            </li>
          </ul>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default PrivacyPolicy;
