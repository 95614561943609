import React, { useState, useEffect } from "react";
import "../Styles/NewNav.css";
import logo from "../images/newLogo.png";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";

const solutions = [
  {
    name: "Demo Videos",
    description: "Learn by watching",
    href: "https://grouple.gitbook.io/docs/product-demos/understanding-the-group-booking-journey",
  },
  {
    name: "Why Grouple?",
    description: "Discover our advantages",
    href: "https://grouple.gitbook.io/docs",
  },
];

const NewNavbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [isResourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);
  const [isBannerVisible, setBannerVisible] = useState(true);
  const [isCookiesVisible, setCookiesVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setCookiesVisible(true);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection2 = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = -75;
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setSticky(false);
      closeSidePanel();
    }
  };

  const toggleSidePanel = () => {
    setSidePanelOpen(!isSidePanelOpen);
  };

  const closeSidePanel = () => {
    setSidePanelOpen(false);
    setResourcesDropdownOpen(false);
  };

  const toggleResourcesDropdown = () => {
    setResourcesDropdownOpen(!isResourcesDropdownOpen);
  };

  const dismissBanner = () => {
    setBannerVisible(false);
  };

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setCookiesVisible(false);
  };

  const handleRejectCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setCookiesVisible(false);
  };

  return (
    <>
      {/* top banner */}
      {isBannerVisible && (
        <div
          className={`flex items-center gap-x-6 bg-[#4A6AFE] px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}
        >
          <p className="text-sm leading-6 text-white">
            <a href="/bookAdemo" rel="noreferrer">
              Book a Demo for a 14-day free trial of Grouple today&nbsp;
              <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={dismissBanner}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}

      {/* navbar */}
      <nav className={`newnav ${isSticky ? "sticky" : ""}`}>
        <div className="newlogo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>

        <div className="newmenu">
          <ul>
            <li>
              <Link
                onClick={(e) => scrollToSection2("features")}
                style={{ color: isSticky ? "#000" : "#fff" }}
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => scrollToSection2("hospitality")}
                style={{ color: isSticky ? "#000" : "#fff" }}
              >
                Industries
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => scrollToSection2("simplify")}
                style={{ color: isSticky ? "#000" : "#fff" }}
              >
                How It Works
              </Link>
            </li>

            {/* Flyout menu */}
            <li>
              <Popover className="relative">
                <PopoverButton className="inline-flex items-center gap-x-1 text-md font-semibold leading-6 text-gray-900">
                  <span style={{ color: isSticky ? "#000" : "#fff" }}>
                    Resources
                  </span>
                  <ChevronDownIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    style={{ color: isSticky ? "#000" : "#fff" }}
                  />
                </PopoverButton>
                <PopoverPanel
                  transition
                  className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className=" max-w-sm flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                      <div className="text-neutral-400 text-start pl-2 text-base font-normal pb-3 tracking-tight">
                        Resources
                      </div>
                      {solutions.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex gap-x-2 rounded-lg p-2 hover:bg-grouple-blue"
                        >
                          <div className="text-start">
                            <a
                              href={item.href}
                              target="_blank"
                              rel="noreferrer"
                              className="font-semibold text-gray-900"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </a>
                            <div className=" text-gray-600">
                              {item.description}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </PopoverPanel>
              </Popover>
            </li>

            <li>
              <Link to="/pricing" style={{ color: isSticky ? "#000" : "#fff" }}>
                Pricing
              </Link>
            </li>
          </ul>
        </div>

        {/* Buttons */}
        <div className="buttons">
          {/* <Link to="https://calendly.com/rohit-grouple" target="_blank">
           */}
          <Link to="/bookAdemo">
            <button className={`newbutton ${isSticky ? "sticky" : ""}`}>
              <p className="button-text"> Book a Demo</p>
            </button>
          </Link>

          <Link to="https://merchant.grouple.tech/" target="_blank">
            <button className={`button2 ${isSticky ? "sticky" : ""}`}>
              <p className="button-text2">Log in</p>
            </button>
          </Link>
        </div>

        <div className="hamburger" onClick={toggleSidePanel}>
          <RxHamburgerMenu />
        </div>
      </nav>

      {/* Sidebar for mobile */}
      <div className={`sidepanel ${isSidePanelOpen ? "open" : ""}`}>
        <div className="closebtn" onClick={closeSidePanel}>
          &times;
          <div className="SidepanelLogoDIv">
            <div className="newlogo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
        <a
          href="#features"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection2("features");
          }}
        >
          Features
        </a>
        <a
          href="#hospitality"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection2("hospitality");
          }}
        >
          Industries
        </a>
        <a
          href="#simplify"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection2("simplify");
          }}
        >
          How It Works
        </a>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            toggleResourcesDropdown();
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Resources{" "}
          {isResourcesDropdownOpen ? (
            <ChevronDownIcon className="h-5 w-5 inline rotate-180" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 inline " />
          )}
        </a>

        {isResourcesDropdownOpen && (
          <div className="resources-dropdown flex flex-col gap-3 pl-8">
            {solutions.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noreferrer"
                className="text-black text-lg font-normal font-['Gilroy-Medium']"
              >
                {item.name}
              </a>
            ))}
          </div>
        )}
        <Link to="/pricing" onClick={closeSidePanel}>
          Pricing
        </Link>
        <div className="buttonsSidepanel">
          <Link to="/bookAdemo" style={{ width: "48%" }}>
            <button
              className={`newbuttonSidepanel ${isSticky ? "sticky" : ""}`}
            >
              <p className="button-textSidepanel"> Book a Demo</p>
            </button>
          </Link>

          <Link
            to="https://merchant.grouple.tech/"
            target="_blank"
            style={{ width: "48%" }}
          >
            <button className={`button2Sidepanel ${isSticky ? "sticky" : ""}`}>
              <p className="button-text2Sidepanel">Log in</p>
            </button>
          </Link>
        </div>
      </div>

      {/* Cookie notice */}
      {isCookiesVisible && (
        <div className="fixed z-50 inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
          <p className="max-w-4xl text-sm leading-6 text-gray-900">
            We use cookies to improve your experience on our website,
            personalize content, to provide social media features, and to
            analyze our traffic. By continuing to browse, you agree to our use
            of cookies. For more information, please read our{" "}
            <a href="/" className="font-semibold text-[#4A6AFE]">
              Cookie policy
            </a>
            .
          </p>
          <div className="flex flex-none items-center gap-x-5">
            <button
              type="button"
              className="rounded-md bg-[#4A6AFE] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              onClick={handleAcceptCookies}
            >
              Accept all
            </button>
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={handleRejectCookies}
            >
              Reject all
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NewNavbar;
