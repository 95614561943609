import React from "react";
import NewNavbarTwo from "../New Components/NewNavbarTwo";
import NewFooter from "../New Components/Footer";
import "../Styles/Contact.css";
import Logos from "../New Components/Logos";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <>
      <NewNavbarTwo />
      <div className="contactBox">
        <h2>Contact Us</h2>
        <h4>
          At Grouple, we value your feedback and are ready to assist with any
          questions or concerns you may have. Whether you’re a merchant looking
          to partner with us or a customer with an inquiry, our team is here to
          provide support.
        </h4>

        <div className="insideContactBox">
          <div>
            <h3>📍Office Address</h3>
            <h6>INDIA</h6>
            <h5>
              BEST, Kanakia Hollywood, Bus station, Yari Rd, Sai Nagar, Versova,
              Andheri West, Mumbai Maharashtra 400061
            </h5>
            <h6>UNITED KINGDOM</h6>
            <h5>33 London St, Reading RG1 4PS, United Kingdom</h5>
          </div>
          <div>
            {" "}
            <h3>📞 Phone</h3>
            <h6>+44 118 950 3925</h6>
            <h5>Monday to Friday: 9 AM - 6 PM</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="1"
              viewBox="0 0 480 1"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M562 1H0L0 0L562 0V1Z"
                fill="#E5E5EA"
              />
            </svg>
            <h3>📧 Email</h3>
            <h5>For support - support@grouple.in</h5>
            <Link to="https://calendly.com/rohit-grouple" style={{textDecoration:"none"}} target="_blank">
              <h6 style={{ cursor: "pointer"}}>
                Click here to book a meeting
              </h6>
            </Link>
          </div>
        </div>
      </div>
      <Logos />
      <NewFooter />
    </>
  );
};

export default ContactUs;
