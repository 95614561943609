import React from "react";
import NewNavbar from "../New Components/NewNavbar";
import NewFooter from "../New Components/Footer";
import aboutImg from "../images/AboutImg.png";
import "../Styles/About.css";
import rohit from "../images/rohit.png";
import amar from "../images/amar.png";
import arun from "../images/arun.png";
import arnav from "../images/arnav.png";
import shubhangi from "../images/shubhangi.png";
import anjali from "../images/anjali.png";
import harshal from "../images/harshal.png";
import yugal from "../images/yugal.png";
import { Link } from "react-router-dom";
import NewNavbarTwo from "../New Components/NewNavbarTwo";

const AboutUs = () => {
  return (
    <>
      <NewNavbarTwo />
      <div className="outerAbout">
        <h2>About Us</h2>

        <div className="aboutParent">
          <div className="leftAbout">
            <img className=" rounded-2xl" src={aboutImg} alt="" />
          </div>

          <div className="rightAbout">
            <h3>Our Story</h3>
            <h4>
              At Grouple, we specialise in simplifying group and private
              bookings for high-value and VIP customers at hospitality events.
              Our Our Group Reservation Management Software empowers operators
              to create, up-sell, and manage group experience packages,
              enhancing guest satisfaction while optimising operational
              efficiency.{" "}
            </h4>
            <h4>
              We leverage cutting-edge technology to offer a suite of advanced
              features, including online group reservations through a dedicated
              microsite, dynamic pricing for experience packages, F&B buffet
              menu management, and guest check-ins, among others. This approach
              streamlines the booking process, boosts revenues, and gathers
              valuable guest data.
            </h4>{" "}
            <h4>
              All the guest needs to do is Book , Invite & show up to Enjoy
            </h4>
          </div>
        </div>
      </div>

      <div className="ourTeam">
        <h2>Our Team</h2>

        <div className="firstLine">
          <div>
            <img src={rohit} alt="" />
            <h4>Rohit Ghosh</h4>
            <h5>FOUNDER & CEO</h5>
            <a
              href="https://www.linkedin.com/in/rohit-g-a820b865/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={amar} alt="" />
            <h4>Amar Bhati</h4>
            <h5>CO FOUNDER & CBO</h5>
            <a
              href="https://www.linkedin.com/in/arun-mahajan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={arun} alt="" />
            <h4>Arun Mahajan</h4>
            <h5>CO FOUNDER & CTO</h5>
            <a
              href="https://www.linkedin.com/in/arun-mahajan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>

        <div className="secondLine">
          <div>
            <img src={arnav} alt="" />
            <h4>Arnav Mahajan</h4>
            <h5>LEAD BACK-END DEVELOPER</h5>
            <a
              href="https://www.linkedin.com/in/arnav-mah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={shubhangi} alt="" />
            <h4>Shubhangi Sisodia</h4>
            <h5>LEAD FRONT-END DEVELOPER</h5>
            <a
              href="https://www.linkedin.com/in/shubhangi-sisodia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={anjali} alt="" />
            <h4>Anjali Singh</h4>
            <h5>LEAD PRODUCT DESIGNER</h5>
            <a
              href="https://www.linkedin.com/in/anjali-singh-ba34a8215/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>

        <div className="firstLine">
          <div>
            <img src={harshal} alt="" />
            <h4>Harshal Bhawsar</h4>
            <h5>FRONT-END DEVELOPER</h5>
            <a
              href="https://www.linkedin.com/in/harshalbhawsar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={yugal} alt="" />
            <h4>Yugal Gupta</h4>
            <h5>UI/UX DESIGNER</h5>
            <a
              href="https://www.linkedin.com/in/yugal-gupta-584b7b161/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="ourTeamMobile">
        <h2>Our Team</h2>

        <div className="firstLine">
          <div>
            <img src={rohit} alt="" />
            <h4>Rohit Ghosh</h4>
            <h5>FOUNDER & CEO</h5>
            <a
              href="https://www.linkedin.com/in/rohit-g-a820b865/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={amar} alt="" />
            <h4>Amar Bhati</h4>
            <h5>CO FOUNDER & CBO</h5>
            <a
              href="https://www.linkedin.com/in/arun-mahajan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        
        </div>

        <div className="firstLine">
        <div>
            <img src={arun} alt="" />
            <h4>Arun Mahajan</h4>
            <h5>CO FOUNDER & CTO</h5>
            <a
              href="https://www.linkedin.com/in/arun-mahajan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={arnav} alt="" />
            <h4>Arnav Mahajan</h4>
            <h5>LEAD BACK-END DEVELOPER</h5>
            <a
              href="https://www.linkedin.com/in/arnav-mah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
         
        </div>

        <div className="firstLine">
        <div>
            <img src={shubhangi} alt="" />
            <h4>Shubhangi Sisodia</h4>
            <h5>LEAD FRONT-END DEVELOPER</h5>
            <a
              href="https://www.linkedin.com/in/shubhangi-sisodia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={anjali} alt="" />
            <h4>Anjali Singh</h4>
            <h5>LEAD PRODUCT DESIGNER</h5>
            <a
              href="https://www.linkedin.com/in/anjali-singh-ba34a8215/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        
        </div>

        <div className="firstLine">
        <div>
            <img src={harshal} alt="" />
            <h4>Harshal Bhawsar</h4>
            <h5>FRONT-END DEVELOPER</h5>
            <a
              href="https://www.linkedin.com/in/harshalbhawsar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <img src={yugal} alt="" />
            <h4>Yugal Gupta</h4>
            <h5>UI/UX DESIGNER</h5>
            <a
              href="https://www.linkedin.com/in/yugal-gupta-584b7b161/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_500_495)">
                  <rect width="20" height="20" rx="2" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5515 0H1.5075C0.692187 0 0 0.644687 0 1.4391V18.5602C0 19.3553 0.454258 20 1.26922 20H18.3132C19.1296 20 20 19.3553 20 18.5602V1.4391C20 0.644687 19.3675 0 18.5515 0ZM7.61887 7.61887H10.3114V8.99137H10.3409C10.7512 8.25133 11.9636 7.50008 13.4628 7.50008C16.3399 7.50008 17.143 9.02773 17.143 11.857V17.143H14.2857V12.3782C14.2857 11.1116 13.7799 10 12.5971 10C11.1609 10 10.4762 10.9722 10.4762 12.5687V17.143H7.61887V7.61887ZM2.85699 17.143H5.71434V7.61887H2.85699V17.143ZM6.07141 4.28566C6.07141 5.2723 5.2723 6.07141 4.28566 6.07141C3.29902 6.07141 2.49992 5.2723 2.49992 4.28566C2.49992 3.29902 3.29902 2.49992 4.28566 2.49992C5.2723 2.49992 6.07141 3.29902 6.07141 4.28566Z"
                    fill="#0077B5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_500_495">
                    <rect width="20" height="20" rx="2" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="joinUs">
        <h2>Join us to elevate hospitality to new heights</h2>
        <Link to="/bookAdemo">
          <button>Book a Demo</button>
        </Link>
      </div>
      <NewFooter />
    </>
  );
};

export default AboutUs;
