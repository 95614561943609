import React, { useState, useEffect } from "react";
import "../Styles/NewHero.css";
import hero1 from "../images/hero1.png";
import ipad from "../images/iPad Pro 11-Inch.png"
import hero2 from "../images/hero2.png";
import hero3 from "../images/hero3.png";
import hero4 from "../images/hero4.png";
import hero5 from "../images/hero5.png";

const NewHero = () => {
  const [currentText, setCurrentText] = useState('experience unique');
  const [transitionClass, setTransitionClass] = useState('slide-in');
  const texts = ['experience unique', 'occasion special', 'event memorable'];

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setTransitionClass('slide-out');
      setTimeout(() => {
        index = (index + 1) % texts.length;
        setCurrentText(texts[index]);
        setTransitionClass('slide-in');
      }, 500); 
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  return (
    <div className="newhero">
      <div className="upper-hero">
        <h2>FOR HOSPITALITY VENUES</h2>
        <h3>Sell Package Deals Faster 
        Manage Groups with Ease</h3>
        {/* <h4 className={`transition-text ${transitionClass}`}>{currentText}</h4> */}
        <h6>The all-in-one software platform designed to boost revenues, enhance guest 
        experiences, and increase operational efficiency.</h6>
      </div>

      <div className="below-hero">
        <img src={ipad} alt="" className="hero1" />
        {/* <div className="inside-hero">
          <img src={hero2} alt="" className="hero2" />
          <img src={hero3} alt="" className="hero3" />
        </div>

        <div className="inside-hero">
          <img src={hero4} alt="" className="hero4" />
          <img src={hero5} alt="" className="hero5" />
        </div> */}
      </div>
    </div>
  );
};

export default NewHero;
