import React, { useEffect, useRef, useState } from "react";
import "../Styles/Hopitality.css";
import rest from "../images/rest.png";
import brew from "../images/brew.png";
import night from "../images/night.png";
import sports from "../images/sports.png";
import dine from "../images/dine.png";
import enter from "../images/enter.png";
import { Carousel } from "antd";
import Award1 from "../images/Award1.png";
import Award2 from "../images/AWARD2.png";
import Award3 from "../images/Award3.png";
import Award4 from "../images/AWARD4.png";
import { AwardsPhone } from "./AwardsPhone";

const Hospitality = () => {
  const carouselRef = useRef();
  const isDesktopOrTablet = () => window.innerWidth >= 768;

  const [autoplay, setAutoplay] = useState(isDesktopOrTablet());

  useEffect(() => {
    const handleResize = () => {
      setAutoplay(isDesktopOrTablet());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="hospitality" id="hospitality">
      <h2>
        Built for venues <br />
        <span>just like you</span>{" "}
      </h2>

      {/* hospitality imgs */}

      <div className="hospi-imgs">
        <div className="image-container">
          <img src={rest} alt="" />
          <div className="text-overlay">Restaurants</div>
        </div>

        <div className="image-container">
          <img src={brew} alt="" />
          <div className="text-overlay">Breweries & wineries</div>
        </div>
        <div className="image-container">
          <img src={night} alt="" />
          <div className="text-overlay">Night clubs & events</div>
        </div>
        <div className="image-container">
          <img src={sports} alt="" />
          <div className="text-overlay">Sports hospitality</div>
        </div>
        <div className="image-container">
          <img src={dine} alt="" />
          <div className="text-overlay">Dinning experiences</div>
        </div>
        <div className="image-container">
          <img src={enter} alt="" />
          <div className="text-overlay">Entertainment</div>
        </div>
      </div>

      <h4>Awards and recognition</h4>
      <div className="AwardsParent">
      <div className="justify-start items-start gap-2.5 inline-flex">
          <img src={Award1} alt="" />
          <div>
            <div className="AwardHeader">STPI CHUNAUTI 4.0WINNERS</div>
            <div className="AwardHeader">2023-24</div>
          </div>
        </div>
        <div className="justify-start items-start gap-2.5 inline-flex">
          <img src={Award2} alt="" />
          <div>
            <div className="AwardHeader">GO GLOBAL AWARDS</div>
            <div className="AwardHeader">2021-22</div>
          </div>
        </div>
        <div className="justify-start items-start gap-2.5 inline-flex">
          <img src={Award3} alt="" />
          <div>
            <div className="AwardHeader">FINANCIAL TIMES SEEDSTARS </div>
            <div className="AwardHeader">2021-22</div>
          </div>
        </div>
        <div className="justify-start items-start gap-2.5 inline-flex">
          <img src={Award4} alt="" />
          <div>
            <div className="AwardHeader">INDIAN ACHIEVER’S AWARD</div>
            <div className="AwardHeader">2020-21</div>
          </div>
        </div>
      </div>


<div className="AwardsPhone">
<AwardsPhone/>
</div>


      {/* what client says  */}

      <h4>What our clients say about us</h4>

      {/* Carousel */}
      <div className="carousel-container">
        <Carousel
          dots={false}
          ref={carouselRef}
          autoplay={autoplay}
          autoplaySpeed={4500}
        >
          <div className="carousel-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="35"
              viewBox="0 0 44 35"
              fill="none"
            >
              <g clip-path="url(#clip0_15_392)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M43.3334 5.46638C36.9428 8.50327 33.7476 12.0716 33.7476 16.1714C36.4715 16.4751 38.7238 17.5506 40.5048 19.398C42.2857 21.2455 43.1762 23.3839 43.1762 25.8134C43.1762 28.3948 42.3119 30.5712 40.5834 32.3427C38.8548 34.1143 36.681 35 34.0619 35C31.1286 35 28.5881 33.8485 26.4405 31.5456C24.2929 29.2426 23.2191 26.4461 23.2191 23.1562C23.2191 13.2863 28.9285 5.56763 40.3476 0L43.3334 5.46638ZM20.781 5.46638C14.3381 8.50327 11.1167 12.0716 11.1167 16.1714C13.8929 16.4751 16.1714 17.5506 17.9524 19.398C19.7334 21.2455 20.6238 23.3839 20.6238 25.8134C20.6238 28.3948 19.7465 30.5712 17.9917 32.3427C16.2369 34.1143 14.05 35 11.431 35C8.49762 35 5.97027 33.8485 3.84883 31.5456C1.72739 29.2426 0.666687 26.4461 0.666687 23.1562C0.666687 13.2863 6.34996 5.56763 17.7167 0L20.781 5.46638Z"
                  fill="#4F65EB"
                />
              </g>
              <defs>
                <clipPath id="clip0_15_392">
                  <rect width="44" height="35" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h3>
              "Grouple has transformed our group booking process. The seamless
              management system has boosted our efficiency and significantly
              improved our guest experience. We highly recommend Grouple to any
              hospitality business looking to streamline their operations."
            </h3>
            <h5>Jospher Nadar</h5>
            <h6>Operations Specialist, Bar Baar</h6>
          </div>
          <div className="carousel-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="35"
              viewBox="0 0 44 35"
              fill="none"
            >
              <g clip-path="url(#clip0_15_392)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M43.3334 5.46638C36.9428 8.50327 33.7476 12.0716 33.7476 16.1714C36.4715 16.4751 38.7238 17.5506 40.5048 19.398C42.2857 21.2455 43.1762 23.3839 43.1762 25.8134C43.1762 28.3948 42.3119 30.5712 40.5834 32.3427C38.8548 34.1143 36.681 35 34.0619 35C31.1286 35 28.5881 33.8485 26.4405 31.5456C24.2929 29.2426 23.2191 26.4461 23.2191 23.1562C23.2191 13.2863 28.9285 5.56763 40.3476 0L43.3334 5.46638ZM20.781 5.46638C14.3381 8.50327 11.1167 12.0716 11.1167 16.1714C13.8929 16.4751 16.1714 17.5506 17.9524 19.398C19.7334 21.2455 20.6238 23.3839 20.6238 25.8134C20.6238 28.3948 19.7465 30.5712 17.9917 32.3427C16.2369 34.1143 14.05 35 11.431 35C8.49762 35 5.97027 33.8485 3.84883 31.5456C1.72739 29.2426 0.666687 26.4461 0.666687 23.1562C0.666687 13.2863 6.34996 5.56763 17.7167 0L20.781 5.46638Z"
                  fill="#4F65EB"
                />
              </g>
              <defs>
                <clipPath id="clip0_15_392">
                  <rect width="44" height="35" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h3>
              "Grouple has made organising and managing events so much easier.
              The software's comprehensive features allow us to handle multiple
              bookings efficiently, ensuring a smooth experience for both our
              clients and our team. Our guests love the personalised concierge
              service, and we've seen more repeat customers."
            </h3>
            <h5>Prasad</h5>
            <h6>Manager, Bar Baar, Dubai, UAE</h6>
          </div>
        </Carousel>
      </div>

      <div className="arrows">
        <div className="left-arrow" onClick={() => carouselRef.current.prev()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.5 10H17.5"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 15L2.5 10L7.5 5"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="right-arrow" onClick={() => carouselRef.current.next()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 10H2.5"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5 5L17.5 10L12.5 15"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Hospitality;
