import React, { useEffect, useState } from "react";
import GroupleLogo from "../images/newLogo.png";
import MarketCategory1 from "../images/MarketCategory1.png";
import MarketCategory2 from "../images/MarketCategory2.png";
import MarketCategory3 from "../images/MarketCategory3.png";
import MarketCategory4 from "../images/MarketCategory4.png";
import "../Styles/MarketPlace.css";
import { Carousel } from "flowbite-react";
import axios from "axios";
import summer from "../images/thisSummer.png";
import nextgen from "../images/nextgen.png";
import FooterTwo from "./FooterTwo";
import { Link } from "react-router-dom";
import { DatePicker } from "rsuite";

const NavItem = ({ children, svgPath, svgViewBox }) => (
  <div className="px-5 justify-center items-center gap-2.5 flex">
    <div className="text-black text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
      {children}
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox={svgViewBox}
      fill="none"
    >
      <path
        d={svgPath}
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const FilterItem = ({ title, description, svgPath, gap, onClick }) => (
  <div
    className={`border-r border-zinc-400 justify-start items-center ${gap} inline-flex cursor-pointer`}
    onClick={onClick}
  >
    <div className="flex-col py-4 pl-8 justify-start items-start inline-flex gap-2">
      <div className="text-neutral-800 text-sm font-normal font-['Gilroy-SemiBold'] tracking-tight">
        {title}
      </div>
      <div className="text-neutral-800 text-base font-normal font-['Gilroy-Regular'] tracking-tight">
        {description}
      </div>
    </div>
    <div className="pr-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d={svgPath}
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  </div>
);

const Card = ({ title, location, price, imageUrl }) => (
  <div className="card">
    <img src={imageUrl} alt={title} />
    <div>
      <div className="text-black text-lg font-normal font-['Gilroy-SemiBold']">
        {title}
      </div>
      <div className="text-black text-sm font-normal font-['Gilroy-Medium']">
        {location}
      </div>
      <div className="text-black text-base font-normal font-['Gilroy-Medium'] pt-[0.65rem]">
        {price}
      </div>
    </div>
  </div>
);

const DateFilter = ({ selectedDate, handleDateChange }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <div className="relative">
      <FilterItem
        title="DATE"
        description={
          selectedDate ? selectedDate.toLocaleDateString() : "Select a date"
        }
        svgPath="M5 7.5L10 12.5L15 7.5"
        gap="gap-14"
        onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
      />
      {isDatePickerOpen && (
        <div className="absolute top-full left-0 mt-2 z-10">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              handleDateChange(date);
              setIsDatePickerOpen(false);
            }}
            inline
          />
        </div>
      )}
    </div>
  );
};



export const MarketPlace = () => {

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        "https://server.testgrouple.com/api/v1/enduser/marketplace/getDetails"
      )
      .then((response) => {
        const data = response.data.data.flatMap((category) =>
          category.estates.map((estate) => ({
            title: estate.estate_name,
            location: estate.estate_address,
            price: estate.price || "₹N/A/person",
            imageUrl: estate.img_path || "default_image_path",
          }))
        );
        setCardsData(data);
        console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching marketplace details:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <nav className="w-full px-20 py-7 border-b border-zinc-300 justify-between items-center inline-flex">
        <img src={GroupleLogo} alt="Grouple Logo" />
        <div className="justify-start items-center gap-3 flex">
          <NavItem
            svgPath="M9.33325 2H13.9999V6.66667 M14 9.82467V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6 M8.6001 7.39999L13.7001 2.29999"
            svgViewBox="0 0 16 16"
          >
            For Business
          </NavItem>
          <div className="justify-start items-start gap-3 flex">
            <div className="px-5 py-2 bg-groupleTheme rounded-[100px] justify-center items-center gap-2.5 flex">
              <div className="text-white text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
                Sign up
              </div>
            </div>
            <div className="px-5 py-2 rounded-[100px] border border-black justify-center items-center gap-2.5 flex">
              <div className="text-black text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
                Log in
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="summer">
        <Carousel>
          <img src={summer} alt="..." />
          <img src={summer} alt="..." />
          <img src={summer} alt="..." />
          <img src={summer} alt="..." />
        </Carousel>
      </div>

      <div className="w-[88%] mt-12 mx-auto bg-white rounded-[100px] border border-zinc-400 flex items-center">
      <FilterItem
        title="VENUES"
        description="Search for venues"
        svgPath="M5 7.5L10 12.5L15 7.5"
        gap="gap-12"
      />
      <FilterItem
        title="LOCATION"
        description="New Delhi, India"
        svgPath="M5 7.5L10 12.5L15 7.5"
        gap="gap-10"
      />
      <DateFilter
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
      <FilterItem
        title="TIME SLOT"
        description="6:00PM - 9:00PM"
        svgPath="M5 7.5L10 12.5L15 7.5"
        gap="gap-10"
      />
      <FilterItem
        title="GUEST SIZE"
        description="125"
        svgPath="M5 7.5L10 12.5L15 7.5"
        gap="gap-36"
      />
      <div className="pl-5">
        <div className="w-11 h-11 p-[13px] bg-groupleTheme rounded-[100px] justify-start items-center gap-2.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M8.25 2.25C6.6587 2.25 5.13258 2.88214 4.00736 4.00736C2.88214 5.13258 2.25 6.6587 2.25 8.25C2.25 9.8413 2.88214 11.3674 4.00736 12.4926C5.13258 13.6179 6.6587 14.25 8.25 14.25C9.8413 14.25 11.3674 13.6179 12.4926 12.4926C13.6179 11.3674 14.25 9.8413 14.25 8.25C14.25 6.6587 13.6179 5.13258 12.4926 4.00736C11.3674 2.88214 9.8413 2.25 8.25 2.25Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 15.75L12.4875 12.4875"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  

      <div className="w-[88%] mx-auto ">
        <div className="text-black text-3xl font-normal font-['Gilroy-SemiBold'] pt-14">
          Venues starts here
        </div>

        <div className="marketCategoryWrapper">
          <img src={MarketCategory1} alt="MarketCategory1" />
          <img src={MarketCategory2} alt="MarketCategory2" />
          <img src={MarketCategory3} alt="MarketCategory3" />
          <img src={MarketCategory4} alt="MarketCategory4" />
        </div>
      </div>

      <div className="w-[88%] mx-auto pb-11">
        <div className="text-black text-3xl font-normal font-['Gilroy-SemiBold'] pt-24">
          Experiences
        </div>
        <div className="card-list">
          {cardsData.map((card, index) => (
            <Link to="/detailVenues">
              <Card
                key={index}
                title={card.title}
                location={card.location}
                price={card.price}
                imageUrl={card.imageUrl}
              />
            </Link>
          ))}
        </div>
      </div>

      <div className="nextGen">
        <div className="leftgen">
          <h2>The Next-Generation Group Reservation Management Software</h2>
          <button>
            For Business
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M9.33337 2H14V6.66667"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 9.82467V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.59998 7.40005L13.7 2.30005"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="rightgen">
          <img src={nextgen} alt="" />
        </div>
      </div>

      <FooterTwo />
    </div>
  );
};
