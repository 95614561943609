import React from "react";
import "../Styles/Simplify.css";
import create from "../images/Create.png";
import upsell from "../images/Upsell.png";
import manage from "../images/Manage.png";

const Simplify = () => {
  return (
    <div className="simplify" id="simplify">
      <h2>
        Empowering operators to
        <br />
        <span>curate unforgettable experiences </span>
      </h2>

      <div className="trippleimgs">
        <div className="image-container">
          <img src={create} alt="" />
          <div className="overlay">
            <h3>1. <span>Create</span></h3>
            <p>
              Easily design tailored packages, minimum spend on tables or set
              course meals to suit different group needs, setting the stage for
              memorable events
            </p>
          </div>
        </div>

        <div className="image-container">
          <img src={upsell} alt="" />
          <div className="overlay">
            <h3>2. <span>Upsell</span></h3>
            <p>
              Recommend premium pairings and add-ons during booking. This
              personalised approach significantly increases the average spend
              per guest.
            </p>
          </div>
        </div>

        <div className="image-container">
          <img src={manage} alt="" />
          <div className="overlay">
            <h3>3. <span>Manage</span></h3>
            <p>
              Centralise operations with an intuitive dashboard. Access group
              sales, guest data, and analytics to simplify workflows and
              increase efficiency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simplify;
