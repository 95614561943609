import React from "react";
import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import NewFooter from "../New Components/Footer";
import "../Styles/BookADemo.css";
import NewNavbarTwo from "../New Components/NewNavbarTwo";

const frequencies = [
  { value: "TwoYearPlan", label: "2 Year Plan", priceSuffix: "/month" },
  { value: "OneYearPlan", label: "1 Year Plan", priceSuffix: "/month" },
];
const tiers = [
  {
    name: "Starter",
    href:"/bookAdemo",
    id: "Starter",
    price: { OneYearPlan: "$49", TwoYearPlan: "$25" },
    description: "Best for standalone chains",
    offer: "14-day trial, no credit card required",
    featureHeader: "Starter features:",
    features: [
      "Includes 1 admin user",
      "Dedicated venue microsite",
      "Online group reservations",
      "Experience packages essentials",
      "Dynamic group pricing",
    ],
    mostPopular: false,
  },
  {
    name: "Professional",
    href:"/bookAdemo",
    id: "Professional",
    price: { OneYearPlan: "$99", TwoYearPlan: "$55" },
    description: "Best for restaurant chains",
    offer: "13% fee on prepayments",
    featureHeader: "Everything in Starter. Plus",
    features: [
      "Up to 3 admin user",
      "A la carte general reservations",
      "Walk-in customer management",
      "Events & club night ticketing",
      "Customisable booking widget",
      "Concierge & SPOC management",
    ],
    mostPopular: false,
  },
  {
    name: "Enterprise",
    href:"/bookAdemo",
    id: "Enterprise",
    price: { OneYearPlan: "Custom", TwoYearPlan: "Custom" },
    description: "Best for hospitality groups",
    offer: "2% fee on prepayments",
    featureHeader: "Everything in Professional. Plus",
    features: [
      "Unlimited user admin",
      "Define member roles & permissions",
      "Experience package categories",
      "Premium add-ons",
      "POS integrations",
      "Advance data & analytics",
      "Dedicated success manager",
    ],
    mostPopular: false,
  },
];
const tiersMobile = [
  {
    name: "Starter",
    id: "Starter",
    price: { OneYearPlan: "$49", TwoYearPlan: "$25" },
    description: "Best for standalone chains",
    offer: "14-day trial, no credit card required",
    featureHeader: "Starter features:",
    features: [
      "Includes 1 admin user",
      "Dedicated venue microsite",
      "Online group reservations",
      "Experience packages essentials",
      "Dynamic group pricing",
      "F&B buffet menu management",
      "Customer profile and CRM",
      "Booking management dashboard",
      "Automated email notifications",
    ],
  },
  {
    name: "Professional",
    id: "Professional",
    price: { OneYearPlan: "$99", TwoYearPlan: "$55" },
    description: "Best for restaurant chains",
    offer: "3% fee on prepayments",
    featureHeader: "Everything in Starter. Plus",
    features: [
      "Up to 3 admin user",
      "A la carte general reservations",
      "Walk-in customer management",
      "Events & club night ticketing",
      "Customisable booking widget",
      "Concierge & SPOC management",
      "Guest invite & RSVP",
      "Group data & guest profile",
      "Integrated split payments",
      "Dedicated microsite",
    ],
  },
  {
    name: "Enterprise",
    id: "Enterprise",
    price: { OneYearPlan: "Custom", TwoYearPlan: "Custom" },
    description: "Best for hospitality groups",
    offer: "2% fee on prepayments",
    featureHeader: "Everything in Professional. Plus",
    features: [
      "Unlimited user admin",
      "Define member roles & permissions",
      "Experience package categories",
      "Premium add-ons",
      "POS integrations",
      "Advance data & analytics",
      "Dedicated success manager",
      "Custom API integrations",
      "24/7 support",
      "Priority on feature requests",
    ],
  },
];

const tiers2 = [
  {
    name: "Starter",
    mostPopular: false,
  },
  {
    name: "Professional",
    mostPopular: false,
  },
  {
    name: "Enterprise",
    mostPopular: false,
  },
];
const sections = [
  {
    features: [
      {
        name: "Members",

        tiers: {
          Starter: "1",
          Professional: "Up to 3",
          Enterprise: "Unlimited",
        },
      },
      {
        name: "Member roles & permissions",

        tiers: {
          Starter: "Admin only",
          Professional: "Admin only",
          Enterprise: "Admin & collaborator",
        },
      },
      {
        name: "Dedicated microsite",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "Online group reservations",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "Experience packages essentials",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "Experience package categories",

        tiers: { Starter: false, Professional: false, Enterprise: true },
      },
      {
        name: "À la carte general reservations",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Walk-in customer management",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Event & club nights ticketing",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Customisable booking widget",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Dynamic group pricing",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "F&B buffet menu management",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "Premium add-ons",

        tiers: { Starter: false, Professional: false, Enterprise: true },
      },
      {
        name: "Concierge & SPOC management",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Customer profile & CRM",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "Guest invite & RSVP",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Group data & guest profile",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Integrated split payments",

        tiers: { Starter: false, Professional: true, Enterprise: true },
      },
      {
        name: "Booking management dashboard",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "Automated email notifications",

        tiers: { Starter: true, Professional: true, Enterprise: true },
      },
      {
        name: "POS integrations",

        tiers: { Starter: false, Professional: false, Enterprise: true },
      },
      {
        name: "Advance data & analytics",

        tiers: { Starter: false, Professional: false, Enterprise: true },
      },
      {
        name: "Dedicated success manager",

        tiers: { Starter: false, Professional: false, Enterprise: true },
      },
    ],
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Pricing = () => {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [showMore, setShowMore] = useState(false);
  const initialFeatures = 5;
  return (
    <>
      <NewNavbarTwo />
      <div className="bg-white py-10 sm:py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <p className="mt-2 text-4xl font-normal tracking-tight text-gray-900 sm:text-[2.5rem]">
              Find the perfect plan for your venue
            </p>
          </div>
          <div className="mx-auto mt-6 sm:max-w-[42%] text-center text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
            Flexible pricing plans designed to fit the needs of any restaurant,
            bar, or entertainment venue. Save more with annual subscriptions.
          </div>
          <div className="mt-16 flex justify-center">
            <fieldset aria-label="Payment frequency">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                {frequencies.map((option) => (
                  <Radio
                    key={option.value}
                    value={option}
                    className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-groupleTheme data-[checked]:text-white"
                  >
                    {option.label}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
          <div className=" pt-5 text-center text-groupleTheme text-sm font-normal font-['Gilroy-SemiBold'] tracking-tight">
            Save 20% on yearly plan
          </div>

          {/* /// web pricing ///// */}
          <div className="cardsOfPricing isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "ring-2 ring-groupleTheme"
                    : "ring-1 ring-gray-200",
                  "rounded-3xl p-8 xl:p-10"
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? "text-groupleTheme" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-groupleTheme">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}
                  </span>
                </p>
                <div className="text-neutral-700 pt-6 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  {tier.offer}
                </div>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "text-groupleTheme ring-1 ring-inset ring-indigo-200 hover:ring-groupleTheme"
                      : "bg-groupleTheme text-white shadow-sm hover:bg-groupleTheme",
                    "mt-6 block rounded-3xl px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-groupleTheme"
                  )}
                >
                  Get started
                </a>
                <div className=" pt-10 text-neutral-800 text-base font-normal font-['Gilroy-Medium'] tracking-tight">
                  {tier.featureHeader}
                </div>
                <ul
                  role="list"
                  className="pt-6 space-y-3 text-sm leading-6 text-neutral-800 font-['Gilroy-Regular'] xl:pt-6"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-black"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* /// mobile pricing ////// */}
          <div className="cardsOfPricingMobile">
            {tiersMobile.map((tier) => (
              <div
                key={tier.id}
                className={`rounded-3xl p-8 xl:p-10 ${
                  tier.mostPopular
                    ? "ring-2 ring-groupleTheme"
                    : "ring-1 ring-gray-200"
                }`}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={`${
                      tier.mostPopular ? "text-groupleTheme" : "text-gray-900"
                    } text-lg font-semibold leading-8`}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular && (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-groupleTheme">
                      Most popular
                    </p>
                  )}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {/* {tier.price.OneYearPlan} */}
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /month
                  </span>
                </p>
                <div className="text-neutral-700 pt-6 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  {tier.offer}
                </div>
                <a
                  href="#"
                  aria-describedby={tier.id}
                  className={`${
                    tier.mostPopular
                      ? "text-groupleTheme ring-1 ring-inset ring-indigo-200 hover:ring-groupleTheme"
                      : "bg-groupleTheme text-white shadow-sm hover:bg-groupleTheme"
                  } mt-6 block rounded-3xl px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-groupleTheme`}
                >
                  Get started
                </a>
                <div className="pt-10 text-neutral-800 text-base font-normal font-['Gilroy-Medium'] tracking-tight">
                  {tier.featureHeader}
                </div>
                <ul
                  role="list"
                  className="pt-6 space-y-3 text-sm leading-6 text-neutral-800 font-['Gilroy-Regular'] xl:pt-6"
                >
                  {tier.features
                    .slice(0, initialFeatures)
                    .map((feature, index) => (
                      <li key={index} className="flex gap-x-3">
                        <CheckIcon
                          aria-hidden="true"
                          className="h-6 w-5 flex-none text-black"
                        />
                        {feature}
                      </li>
                    ))}
                  {showMore &&
                    tier.features
                      .slice(initialFeatures)
                      .map((feature, index) => (
                        <li
                          key={index + initialFeatures}
                          className="flex gap-x-3"
                        >
                          <CheckIcon
                            aria-hidden="true"
                            className="h-6 w-5 flex-none text-black"
                          />
                          {feature}
                        </li>
                      ))}
                </ul>

                {/* view more button */}
                <button
                  onClick={() => setShowMore(!showMore)}
                  className="mt-4 flex items-center justify-center text-blue-500 w-full gap-2"
                >
                  {showMore ? (
                    <>
                     Less features
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M9 5L5 1L1 5"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      More features
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M1 1L5 5L9 1"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white pt-10 sm:pt-10 pb-28">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="isolate mt-20 hidden lg:block">
            <div className="relative -mx-8">
              {tiers2.some((tier2) => tier2.mostPopular) ? (
                <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                  <div
                    style={{
                      marginLeft: `${
                        (tiers2.findIndex((tier2) => tier2.mostPopular) + 1) *
                        25
                      }%`,
                    }}
                    aria-hidden="true"
                    className="flex w-1/4 px-4"
                  >
                    <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                  </div>
                </div>
              ) : null}
              <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                <thead>
                  <tr>
                    <td className="text-black pb-5 text-xl text-center font-normal font-['Gilroy-SemiBold'] tracking-tight">
                      Compare plan
                    </td>
                    <th style={{ width: "24px" }}></th>
                    {tiers2.map((tier, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="text-black pb-5 text-xl text-center font-normal font-['Gilroy-SemiBold'] tracking-tight"
                      >
                        {tier.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1200"
                  height="1"
                  viewBox="0 0 1200 1"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1200 1H0V0H1200V1Z"
                    fill="#D8D8DC"
                  />
                </svg>
                <tbody>
                  {sections.map((section, sectionIdx) => (
                    <Fragment key={sectionIdx}>
                      {section.features.map((feature) => (
                        <tr key={feature.name}>
                          <th
                            scope="row"
                            className="py-4 text-sm text-center font-normal leading-6 text-gray-900"
                          >
                            {feature.name}
                            <div className="absolute inset-x-8 mt-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1200"
                                height="1"
                                viewBox="0 0 1200 1"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M1200 1H0V0H1200V1Z"
                                  fill="#D8D8DC"
                                />
                              </svg>
                            </div>
                          </th>
                          <td className="text-center" style={{ width: "24px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9C2.25 10.7902 2.96116 12.5071 4.22703 13.773C5.4929 15.0388 7.20979 15.75 9 15.75Z"
                                stroke="#7C7C80"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9 12.75V12.7567"
                                stroke="#7C7C80"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9 10.1227C8.98619 9.87921 9.05187 9.63785 9.18714 9.43494C9.32241 9.23204 9.51995 9.07858 9.75 8.99768C10.0319 8.88988 10.2849 8.71811 10.4892 8.4959C10.6934 8.27369 10.8433 8.0071 10.927 7.71712C11.0107 7.42715 11.0259 7.1217 10.9715 6.82483C10.9171 6.52796 10.7946 6.24777 10.6135 6.00632C10.4324 5.76487 10.1977 5.56875 9.92797 5.4334C9.65821 5.29805 9.36071 5.22716 9.05889 5.22633C8.75708 5.22549 8.45919 5.29472 8.18868 5.42857C7.91817 5.56242 7.68243 5.75724 7.5 5.99768"
                                stroke="#7C7C80"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </td>
                          {tiers2.map((tier, index) => (
                            <td key={index} className="px-6 py-4 xl:px-8">
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <div className="text-center text-sm leading-6 text-gray-500">
                                  {feature.tiers[tier.name]}
                                </div>
                              ) : (
                                <>
                                  {feature.tiers[tier.name] === true ? (
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="mx-auto h-5 w-5 text-indigo-600"
                                    />
                                  ) : (
                                    " "
                                  )}

                                  <span className="sr-only">
                                    {feature.tiers[tier.name] === true
                                      ? "Included"
                                      : "Not included"}{" "}
                                    in {tier.name}
                                  </span>
                                </>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default Pricing;
