import React from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import "../Styles/Hopitality.css";
import Award1 from "../images/Award1.png";
import Award2 from "../images/AWARD2.png";
import Award3 from "../images/Award3.png";
import Award4 from "../images/AWARD4.png";

const animation = { duration: 10000, easing: (t) => t };

export const AwardsPhone = () => {
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: 1.5,
      spacing: 4,
    },
    loop: true,
    renderMode: "performance",
    drag: false,
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });

  return (
    <div className="AwardsPhoneWrapper">
      <div ref={sliderRef} className="keen-slider AwardsPhoneWrapper-inner">
        <div className="keen-slider__slide AwardSlider">
          <div className="justify-start w-full items-start gap-2.5 inline-flex">
            <img src={Award1} alt="" />
            <div>
              <div className="AwardHeader">STPI CHUNAUTI 4.0WINNERS</div>
              <div className="AwardHeader">2023-24</div>
            </div>
          </div>
        </div>
        <div className="keen-slider__slide AwardSlider">
          {" "}
          <div className="justify-start w-full items-start gap-2.5 inline-flex pr-4">
            <img src={Award2} alt="" />
            <div>
              <div className="AwardHeader">GO GLOBAL AWARDS</div>
              <div className="AwardHeader">2021-22</div>
            </div>
          </div>
        </div>
        <div className="keen-slider__slide AwardSlider">
          {" "}
          <div className="justify-start items-start gap-2.5 inline-flex">
            <img src={Award3} alt="" />
            <div>
              <div className="AwardHeader">FINANCIAL TIMES SEEDSTARS </div>
              <div className="AwardHeader">2021-22</div>
            </div>
          </div>
        </div>
        <div className="keen-slider__slide AwardSlider">
          <div className="justify-start items-start gap-2.5 inline-flex">
            <img src={Award4} alt="" />
            <div>
              <div className="AwardHeader">INDIAN ACHIEVER’S AWARD</div>
              <div className="AwardHeader">2020-21</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
