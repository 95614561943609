import React, { useEffect, useState } from "react";
import GroupleLogo from "../images/newLogo.png";
import "../Styles/MarketPlace.css";
import axios from "axios";
import FooterTwo from "./FooterTwo";

const NavItem = ({ children, svgPath, svgViewBox }) => (
  <div className="px-5 justify-center items-center gap-2.5 flex">
    <div className="text-black text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
      {children}
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox={svgViewBox}
      fill="none"
    >
      <path
        d={svgPath}
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);



const Card = ({ title, location, price, imageUrl }) => (
  <div className="card">
    <img src={imageUrl} alt={title} />
    <div>
      <div className="text-black text-lg font-normal font-['Gilroy-SemiBold']">
        {title}
      </div>
      <div className="text-black text-sm font-normal font-['Gilroy-Medium']">
        {location}
      </div>
      <div className="text-black text-base font-normal font-['Gilroy-Medium'] pt-[0.65rem]">
        {price}
      </div>
    </div>
  </div>
);

export const DetailVenue = () => {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        "https://server.testgrouple.com/api/v1/enduser/marketplace/getDetails"
      )
      .then((response) => {
        const data = response.data.data.flatMap((category) =>
          category.estates.map((estate) => ({
            title: estate.estate_name,
            location: estate.estate_address,
            price: estate.price || "₹N/A/person",
            imageUrl: estate.img_path || "default_image_path",
          }))
        );
        setCardsData(data);
        console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching marketplace details:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <nav className="w-full px-20 py-7 border-b border-zinc-300 justify-between items-center inline-flex">
        <img src={GroupleLogo} alt="Grouple Logo" />
        <div className="justify-start items-center gap-3 flex">
          <NavItem
            svgPath="M9.33325 2H13.9999V6.66667 M14 9.82467V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6 M8.6001 7.39999L13.7001 2.29999"
            svgViewBox="0 0 16 16"
          >
            For Business
          </NavItem>
          <div className="justify-start items-start gap-3 flex">
            <div className="px-5 py-2 bg-groupleTheme rounded-[100px] justify-center items-center gap-2.5 flex">
              <div className="text-white text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
                Sign up
              </div>
            </div>
            <div className="px-5 py-2 rounded-[100px] border border-black justify-center items-center gap-2.5 flex">
              <div className="text-black text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
                Log in
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="w-[88%] mt-12 mx-auto bg-white  flex items-center gap-3">
        <div className="mt-2 relative">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Search venues, experiences"
            className="custom-place block w-[19rem] rounded-full border-0 px-6 py-2.5 pl-10 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.25 2.25C6.6587 2.25 5.13258 2.88214 4.00736 4.00736C2.88214 5.13258 2.25 6.6587 2.25 8.25C2.25 9.8413 2.88214 11.3674 4.00736 12.4926C5.13258 13.6179 6.6587 14.25 8.25 14.25C9.8413 14.25 11.3674 13.6179 12.4926 12.4926C13.6179 11.3674 14.25 9.8413 14.25 8.25C14.25 6.6587 13.6179 5.13258 12.4926 4.00736C11.3674 2.88214 9.8413 2.25 8.25 2.25Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.7498 15.75L12.4873 12.4875"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>

        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="New Delhi, India"
            className="custom-place block w-[11rem] rounded-full border-0 px-6 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Wed, Jul 10"
            className="custom-place block w-[9rem] rounded-full border-0 px-6 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="6:00PM - 9:00PM"
            className="custom-place block w-[11.5rem] rounded-full border-0 px-6 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="125 guests"
            className="custom-place block w-[8rem] rounded-full border-0 px-6 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2 relative">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Filters"
            className="custom-place block w-[8rem] rounded-full border-0 px-10 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M10.5 6C10.8978 6 11.2794 5.84196 11.5607 5.56066C11.842 5.27936 12 4.89782 12 4.5C12 4.10218 11.842 3.72064 11.5607 3.43934C11.2794 3.15804 10.8978 3 10.5 3C10.1022 3 9.72064 3.15804 9.43934 3.43934C9.15804 3.72064 9 4.10218 9 4.5C9 4.89782 9.15804 5.27936 9.43934 5.56066C9.72064 5.84196 10.1022 6 10.5 6Z"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 4.5H9"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 4.5H15"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 10.5C6.39782 10.5 6.77936 10.342 7.06066 10.0607C7.34196 9.77936 7.5 9.39782 7.5 9C7.5 8.60218 7.34196 8.22064 7.06066 7.93934C6.77936 7.65804 6.39782 7.5 6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5Z"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 9H4.5"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.5 9H15"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.75 15C13.1478 15 13.5294 14.842 13.8107 14.5607C14.092 14.2794 14.25 13.8978 14.25 13.5C14.25 13.1022 14.092 12.7206 13.8107 12.4393C13.5294 12.158 13.1478 12 12.75 12C12.3522 12 11.9706 12.158 11.6893 12.4393C11.408 12.7206 11.25 13.1022 11.25 13.5C11.25 13.8978 11.408 14.2794 11.6893 14.5607C11.9706 14.842 12.3522 15 12.75 15Z"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3 13.5H11.25"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.25 13.5H15"
                stroke="black"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="w-[88%] mx-auto pb-11 pt-14">
        <div className="card-list">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              location={card.location}
              price={card.price}
              imageUrl={card.imageUrl}
            />
          ))}
        </div>
      </div>

      <FooterTwo />
    </div>
  );
};
