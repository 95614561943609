import "../css/terms.css";
import Navbar1 from "../components/nav1";
import Footer from "../components/footer";
import React, { useState, useEffect } from "react";

const Terms = () => {
  const [activeTab, setActiveTab] = useState("tab_item_1");
  const [activeIndex, setActiveIndex] = useState(null);

  const handleTabClick = (tabData) => {
    setActiveTab(tabData);
  };

  const headings = [
    { title: 'Terms Of Service ', content: ' Welcome to Grouple, a group booking platform Service,provided by Ghosh Technology Private Limited. Located in Mumbai, Maharashtra. By using our Service, you agree to comply with the following terms and conditions. Please read them carefully. Violation of any of the terms may result in the termination of your account.' },
    { title: 'Account Terms', content: ' Grouple may communicate with you via email regarding your account, updates, news, and other account-related issues. By using our Service,  you are automatically subscribed to our mailing lists, but you can opt out from receiving emails.You are responsible for maintaining the security of your account and password. Grouple cannot be held liable for any loss or damage resulting from your failure to comply with this security obligation. You are solely responsible for all content posted and activity that occurs under your account,including user-generated content. If we suspect that you are abusing the service, we may temporarily suspend your account and request your cooperation in resolving the matter. Grouple reserves the right to accept or refuse any potential client. We have sole discretion over whether to allow you to use the Service.' },
    { title: 'Copyright and Ownership', content: 'Grouple or its suppliers own the intellectual property rights to all protectable components of the Service. This includes the name of the Service, artwork, end-user interface elements, individual features, and related documentation. You may not copy, modify, adapt, reproduce, distribute, reverse engineer, decompile, or disassemble any aspect of the Service owned by Grouple or its suppliers.' },
    { title: 'General Conditions', content: 'Your use of the Service, including any content, information, or functionality within it is provided "as is" and "as available" without any representations or warranties of any kind, either expressed or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.  You assume full responsibility and risk for your use of this Service.  We continuously improve and change our Services.  Grouple reserves the right to modify or discontinue the Service (or any part thereof, including pricing) temporarily or permanently, with or without notice. Grouple shall not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Service. Any new features that enhance the current Service, including new tools and resources, will be subject to these Terms of Service. By continuing to use the Service after any amendments are posted to Grouples website, you agree to and accept the amended Terms of Service.' },
    { title: 'Data Ownership', content: 'Grouple does not claim any intellectual property rights over the material you provide to the Service. We collect users names, email addresses, designation, company names,  IP addresses, and device data. We use this information solely to provide the Service, including supporting and processing orders, authentication. We do not claim any rights over this Personal Information. You agree to post a privacy policy on your website that complies with the laws applicable to your business. You also agree to obtain consent from your customers for the use and access of their Personal Information. When you upload, submit, store, send, or receive content through the Service, you grant Grouple the right to use this content for the limited purpose of operating, promoting, and improving the Service, and to develop new services. This right continues even if you stop using the Service. Your data is always available to you. Upon request, you will receive a backup of your data in a structured format.' },
    { title: 'Limitation of Liability', content: 'You expressly understand and agree that Grouple shall not be liable for any direct,indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use of or inability to use the Service. In no event shall Grouple or our suppliers be liable for lost profits or any special, incidental, or consequential  damages arising out of or in connection with our site, our Services, or these Terms of Service (however arising, including negligence). You agree to indemnify and hold us, our parent, subsidiaries, affiliates, Your use of the Service is at your sole risk. The Service is provided on an "as is" and "as available" basis without any warranty or condition, express, implied, or statutory. Grouple does not warrant that the Service will be uninterrupted, timely, secure, or error-free. Grouple does not warrant that the results obtained from the use of the Service will be accurate or reliable' },
    { title: 'About these terms', content: 'We may modify these terms or any additional terms that apply to the Service to reflect changes to the law or our Services. You should review the terms regularly. We will post modifications to these terms on this page. Changes will not apply retroactively and will become effective immediately.  If you do not agree to the modified terms for a Service, you should discontinue using it. These terms control the relationship between Grouple and you. They do not create any third-party beneficiary rights. The Terms of Service represent the entire understanding between you and Grouple regarding the  Service and supersede any prior agreements between you and Grouple (including prior versions of the Terms of Service). If we do not take immediate action against any breach of these terms, it does not mean that we are waiving any rights we may have (such as taking action in the future). If a particular term is deemed unenforceable, it will not affect the enforceability of the remaining terms. Disputes arising out of or relating to these terms or the Services shall be governed by and constructed with the laws of  India. For information about how to contact Grouple, please visit our contact page.' }
    
  ];

  const handleHeadingClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (activeIndex !== null) {
      const activeContent = document.querySelector(".dropdown-content.active");
      if (activeContent) {
        const contentOffset = activeContent.offsetTop;
        const offset = 170;
        window.scrollTo({ top: contentOffset - offset, behavior: "smooth" });
      }
    }
  }, [activeIndex]);


  return (
    <>
      <Navbar1 />
      <section className="terms">
        <div className="tc_wrap">
          <div className="tabs_list">
            <ul>
              <li
                data-tc="tab_item_1"
                className={activeTab === "tab_item_1" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_1")}
              >
                Terms of Service
              </li>
              <li
                data-tc="tab_item_2"
                className={activeTab === "tab_item_2" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_2")}
              >
                Account Terms
              </li>
              <li
                data-tc="tab_item_3"
                className={activeTab === "tab_item_3" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_3")}
              >
                Copyright & Ownership
              </li>
              <li
                data-tc="tab_item_4"
                className={activeTab === "tab_item_4" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_4")}
              >
                General Conditions
              </li>
              <li
                data-tc="tab_item_5"
                className={activeTab === "tab_item_5" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_5")}
              >
                Data Ownership
              </li>

              <li
                data-tc="tab_item_6"
                className={activeTab === "tab_item_6" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_6")}
              >
                Limitation of Liability
              </li>

              <li
                data-tc="tab_item_7"
                className={activeTab === "tab_item_7" ? "active" : ""}
                onClick={() => handleTabClick("tab_item_7")}
              >
                About these terms
              </li>
            </ul>
          </div>
          <div className="tabs_content">
            <div className="tab_head">
              <h2>Terms & Conditions</h2>
            </div>
            <div className="tab_body">
              <div
                className={`tab_item ${activeTab === "tab_item_1" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_1" ? "block" : "none" }}
              >
               
                <p>
                  Welcome to Grouple, a group booking platform Service,
                  provided by Ghosh Technology Private Limited. Located in Mumbai, Maharashtra.
                  By using our Service, you agree to comply with the following terms and conditions.
                  Please read them carefully. Violation of any of the terms may result in the termination of your account.
                </p>

              </div>
              <div
                className={`tab_item ${activeTab === "tab_item_2" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_2" ? "block" : "none" }}
              >
               
                <p>
                  Grouple may communicate with you via email regarding your account,
                  updates, news, and other account-related issues. By using our Service,
                  you are automatically subscribed to our mailing lists, but you can opt out from receiving emails.
                  You are responsible for maintaining the security of your account and password. 
                  <p>Grouple cannot be held liable for any loss or damage resulting from your failure to comply with this security obligation.
                  You are solely responsible for all content posted and activity that occurs under your account,
                  including user-generated content.
                  If we suspect that you are abusing the service, we may temporarily suspend your
                  account and request your cooperation in resolving the matter.
                  Grouple reserves the right to accept or refuse any potential client.
                  We have sole discretion over whether to allow you to use the Service.</p>
                </p>

              </div>
              <div
                className={`tab_item ${activeTab === "tab_item_3" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_3" ? "block" : "none" }}
              >
            
                <p>
                  Grouple or its suppliers own the intellectual property rights to all
                  protectable components of the Service. This includes the name of the Service,
                  artwork, end-user interface elements, individual features, and related documentation.
                  You may not copy, modify, adapt, reproduce, distribute, reverse engineer, decompile,
                  or disassemble any aspect of the Service owned by Grouple or its suppliers.

                </p>

              </div>
              <div
                className={`tab_item ${activeTab === "tab_item_4" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_4" ? "block" : "none" }}
              >

                <p>
                  Your use of the Service, including any content, information, or functionality within it,
                  is provided "as is" and "as available" without any representations or warranties of any kind,
                  either expressed or implied, including but not limited to, the implied warranties of merchantability,
                  fitness for a particular purpose, and non-infringement.
                  You assume full responsibility and risk for your use of this Service.
                  We continuously improve and change our Services. 
                  <p>Grouple reserves the right to modify or discontinue the Service
                  (or any part thereof, including pricing) temporarily or permanently, with or without notice.
                  Grouple shall not be liable to you or any third party for any modification, price change, suspension,
                  or discontinuance of the Service.
                  Any new features that enhance the current Service, including new tools and resources,
                  will be subject to these Terms of Service. By continuing to use the Service after any
                  amendments are posted to Grouple's website, you agree to and accept the amended Terms of Service.</p>


                </p>
                {/* More content for Terms of Use */}
              </div>
              <div
                className={`tab_item ${activeTab === "tab_item_5" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_5" ? "block" : "none" }}
              >

                <p>
                  Grouple does not claim any intellectual property rights over the material you provide to the Service.
                  We collect users names, email addresses, designation, company names,  IP addresses, and device data.
                  We use this information solely to provide the Service, including supporting and processing orders, authentication.
                  We do not claim any rights over this Personal Information.
                  You agree to post a privacy policy on your website that complies with the laws applicable to your business.
                 <p> You also agree to obtain consent from your customers for the use and access of their Personal Information.
                  When you upload, submit, store, send, or receive content through the Service,
                  you grant Grouple the right to use this content for the limited purpose of operating, promoting, and improving the Service,
                  and to develop new services. This right continues even if you stop using the Service.
                  Your data is always available to you. Upon request, you will receive a backup of your data in a structured format.</p>


                </p>

              </div>


              <div
                className={`tab_item ${activeTab === "tab_item_6" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_6" ? "block" : "none" }}
              >

                <p>
                  You expressly understand and agree that Grouple shall not be liable for any direct,
                  indirect, incidental, special, consequential, or exemplary damages, including but not limited to,
                  damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use of or inability to use the Service.
                  In no event shall Grouple or our suppliers be liable for lost profits or any special, incidental, or consequential
                  damages arising out of or in connection with our site, our Services, or these Terms of Service (however arising, including negligence).
                 
                 <p>You agree to indemnify and hold us, our parent, subsidiaries, affiliates,
                  Your use of the Service is at your sole risk. The Service is provided on an "as is" and "as available"
                  basis without any warranty or condition, express, implied, or statutory.
                  Grouple does not warrant that the Service will be uninterrupted, timely, secure, or error-free.
                  Grouple does not warrant that the results obtained from the use of the Service will be accurate or reliable.</p> 


                </p>

              </div>

              <div
                className={`tab_item ${activeTab === "tab_item_7" ? "active" : ""
                  }`}
                style={{ display: activeTab === "tab_item_7" ? "block" : "none" }}
              >

                <p>
                  We may modify these terms or any additional terms that apply to the Service to reflect changes to the 
                  law or our Services. You should review the terms regularly. We will post modifications to these terms on this page.
                   Changes will not apply retroactively and will become effective immediately.
                    If you do not agree to the modified terms for a Service, you should discontinue using it.
                  These terms control the relationship between Grouple and you. 
                  They do not create any third-party beneficiary rights.
                  <p>The Terms of Service represent the entire understanding between you and Grouple regarding the 
                  Service and supersede any prior agreements between you and Grouple (including prior versions of the Terms of Service).
                  If we do not take immediate action against any breach of these terms,
                   it does not mean that we are waiving any rights we may have (such as taking action in the future).
                  If a particular term is deemed unenforceable, it will not affect the enforceability of the remaining terms.
                  Disputes arising out of or relating to these terms or the Services shall be governed by and constructed with the laws of  India.
                  For information about how to contact Grouple, please visit our contact page.</p>

                </p>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="terms2">
        <h1>Terms And Conditions</h1>
        <div className="dropdown">
          {headings.map((heading, index) => (
            <div key={index}>
              <button
                className="dropdown-btn"
                onClick={() => handleHeadingClick(index)}
              >
                {heading.title}
              </button>
              <div
                className={`dropdown-content ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                {activeIndex === index && <p>{heading.content}</p>}
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
