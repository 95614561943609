import React, { useState } from "react";
import "../Styles/MobileSimplify.css";
import create1 from "../images/create1.png";
import upsell1 from "../images/upsell1.png";
import manage1 from "../images/manage1.png";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

const MobileSimplify = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 4500);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <div className="mobile-simplify">
      <h2>
        Empowering operators to
        <br />
        <span>curate unforgettable experiences </span>
      </h2>

      <div ref={sliderRef} className="keen-slider">
        <div className="keen-slider__slide">
          {/* card 1 */}
          <div className="inside-simplify">
            <img src={create1} alt="" />

            <div className="below-simplify">
              <h3>1. Create</h3>
              <h4>
                Easily design tailored packages, minimum spend on tables or set
                course meals to suit different group needs, setting the stage
                for memorable events
              </h4>
            </div>
          </div>
        </div>
        <div className="keen-slider__slide">
          {/* card2  */}
          <div className="inside-simplify">
            <img src={upsell1} alt="" />

            <div className="below-simplify">
              <h3>2. Upsell</h3>
              <h4>
                Recommend premium pairings & add-ons to customers during the
                booking. This personalised approach significantly increases the
                average spend per guest.
              </h4>
            </div>
          </div>
        </div>
        <div className="keen-slider__slide">
          {" "}
          {/* card 3 */}
          <div className="inside-simplify">
            <img src={manage1} alt="" />

            <div className="below-simplify">
              <h3>3. Manage</h3>
              <h4>
                Centralise operations with an intuitive dashboard. Access group
                sales, guest data, and analytics to simplify workflows and
                increase efficiency.
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="dots">
        {[
          ...Array(
            slider && slider.current
              ? slider.current.track.details.slides.length
              : 0
          ),
        ].map((_, idx) => (
          <button
            key={idx}
            onClick={() => {
              if (slider && slider.current) {
                slider.current.moveToIdx(idx);
              }
            }}
            className={"dot" + (currentSlide === idx ? " active" : "")}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileSimplify;
