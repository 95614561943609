import React , {useRef ,  useState} from 'react'
import "../css/footer.css"
import logo from "../images/Vector.png"
import {AiFillInstagram} from "react-icons/ai"
import {BsTwitter} from "react-icons/bs"
import {BsLinkedin} from "react-icons/bs"
import { RxCross2 } from "react-icons/rx"
import emailjs from '@emailjs/browser';


const Footer = () => {
  const form = useRef();
  const [modal, setModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const sendEmail = (e) => {
    e.preventDefault();
  
    const isFormValid = Array.from(form.current.elements).every(
      (element) => element.value.trim() !== ''
    );
    if (!isFormValid) {
      alert('Please fill in all the input fields.');
      return;
    }
  
    const submitButton = form.current.querySelector('.footerBtn');
    submitButton.disabled = true;
  

    emailjs.sendForm('service_6bca4a3', 'template_0545jsi', form.current, '3d2KjYMVqxFZbe1Nu')
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          toggleModal();
          setFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      )
      .finally(() => {
        submitButton.disabled = false;
      });

  
    }

  return (

    <div className='footer'>

      <form className='signup' ref={form} onSubmit={sendEmail}>

        <h3>Book a Demo for early access to Grouple ! </h3>
        <input type="email"  name="users_email" placeholder='Email Address' required/>
  
        <input className='footerBtn' name='footer-signup' type="submit" value="Sign Up"    />
        </form> 
     
      
      <div className='footer-container'>
      <div className='sitemap'>
        <h3>Sitemap</h3>
        <ul className='list'>
          <li> <a href="/about" style={{color: "black"}}> About</a></li>
          <li> <a href="/contact" style={{color: "black"}}>Contact</a></li>
          <li> <a href="/blog" style={{color: "black"}}>Blog</a></li>
          <li> <a href="/terms" style={{color: "black"}}>Terms & Conditions </a></li>
          <li> <a href="/privacy" style={{color: "black"}}>Privacy Policy </a></li>
        </ul>
      </div>

      <div className='address'>
        <img src={logo} alt="logo" />
       <h5>Grouple™ designed with love,
        for groups to create memories together. Copyright 2022
        ©Ghosh Technology Pvt. Ltd.
        All Rights Reserved. </h5> 
        <div className='logos'>
         <a className="insta" href="https://twitter.com/Grouple_exp" target='blank'> <BsTwitter /> </a>
         <a className="insta" href="https://www.instagram.com/grouple_in/?hl=en" target='blank'> <AiFillInstagram /> </a>
          <a className="insta" href='https://www.linkedin.com/company/grouple/?originalSubdomain=in' target='blank'> <BsLinkedin /> </a>
        </div>
      </div>
      </div>


      {formSubmitted && modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h2>Thank you for contacting us.!</h2>
           
           
            <button className="close-modal" onClick={toggleModal}>
             <RxCross2 />
            </button>
          </div>
        </div>
      )}

    </div>

    
  )
}

export default Footer