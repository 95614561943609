import React, { useEffect, useState } from "react";
import NewFooter from "../New Components/Footer";
import BookImg from "../images/Rectangle 6376.png";
import Logos from "../New Components/Logos";
import "../Styles/BookADemo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import emailjs from "@emailjs/browser";
import NewNavbarTwo from "../New Components/NewNavbarTwo";

const serviceID = "service_pooyq56";
const templateID = "template_66zg908";
const publicKey = "3d2KjYMVqxFZbe1Nu";

export const BookADemo = () => {
  const [countries, setCountries] = useState([]);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [showNotification2, setShowNotification2] = useState(false);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    website: "",
    industry: "",
    phoneNumber: "",
    dialCode: "+91",
    numberOfVenues: "",
    location: "",
    promoCode: "",
    lookingFor: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.businessName ||
      !formData.industry ||
      !formData.phoneNumber ||
      !formData.numberOfVenues ||
      !formData.location ||
      !formData.lookingFor
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    if (isSubmitting2) return;

    setIsSubmitting2(true);
    const d = formData.dialCode.split("+");
    const phoneNumberWithoutDialCode = formData.phoneNumber.replace(d[1], "");

    const templateParams = {
      user_email: formData.email,
      user_name: `${formData.firstName} ${formData.lastName}`,
      phone_number: `${formData.dialCode} - ${phoneNumberWithoutDialCode}`,
      business_name: formData.businessName,
      website: formData.website,
      industry: formData.industry,
      number_of_venues: formData.numberOfVenues,
      location: formData.location,
      promo_code: formData.promoCode,
      looking_for: formData.lookingFor,
    };
    console.log(templateParams);

    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log("Email Sent Successfully", response);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          businessName: "",
          website: "",
          industry: "",
          phoneNumber: "",
          dialCode: "+91",
          numberOfVenues: "",
          location: "",
          promoCode: "",
          lookingFor: "",
        });
        setIsSubmitting2(false);
        setShowNotification2(true);
        setTimeout(() => setShowNotification2(false), 3000);
      })
      .catch((error) => {
        console.error("Error sending Mail: ", error);
        setIsSubmitting2(false);
      });
  };

  return (
    <>
      <NewNavbarTwo />
      <div className="outerDemoParent">
        <h2>Book a demo</h2>
        <h3>
          Our demo sessions are personalised to fit your unique needs. Let’s
          take your venue to the next level together.
        </h3>
        <div className="BookaDemoParent">
          <div className="BookaDemoRightWrapper">
            <img
              className="h-full w-full object-cover rounded-3xl"
              src={BookImg}
              alt=""
            />
            <div className="absolute top-0 left-0 w-full h-full flex items-end p-7 justify-center lg:text-4xl sm:text-3xl font-normal font-['Gilroy-SemiBold'] tracking-wide text-white">
              Transfer your venue’s group management and guest experience.
            </div>
          </div>

          <form onSubmit={handleSubmit} className="BookaDemoLeftWrapper">
            <div className="LeftInputsWrapper">
              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  First name*
                </div>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="px-4 py-3 w-full rounded-lg border border-zinc-300 text-sm font-normal font-['Gilroy-Medium'] focus:outline-black tracking-tight"
                />
              </div>

              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Last name*
                </div>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight"
                />
              </div>
            </div>

            <div className="LeftInputsWrapper">
              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Work email*
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Work email"
                  value={formData.email}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight"
                />
              </div>

              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Business name*
                </div>
                <input
                  type="text"
                  name="businessName"
                  placeholder="Business name"
                  value={formData.businessName}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight"
                />
              </div>
            </div>

            <div className="LeftInputsWrapper">
              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Business website
                </div>
                <input
                  type="text"
                  name="website"
                  placeholder="Business website"
                  value={formData.website}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight"
                />
              </div>

              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Industry*
                </div>
                <select
                  name="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight appearance-none"
                  style={{
                    background: `url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23b2b2b2" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path></svg>') no-repeat right 1rem center/1.5rem`,
                  }}
                >
                  <option value="" disabled hidden>
                    Industry
                  </option>
                  <option value="Restaurant">Restaurant</option>
                  <option value="Breweries & Wineries">
                    Breweries & Wineries
                  </option>
                  <option value="Night clubs & events">
                    Night clubs & events
                  </option>
                  <option value="Sports hospitality">Sports hospitality</option>
                  <option value="Dining experiences">Dining experiences</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="LeftInputsWrapper">
              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Phone number*
                </div>
                <PhoneInput
                  country={"in"}
                  inputStyle={{
                    width: "100%",
                    padding: "1.4rem 1rem 1.4rem 4rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #d4d4d8",
                  }}
                  dropdownStyle={{
                    padding: "1.4rem",
                    height: "80rem",
                    width: "35rem",
                  }}
                  buttonStyle={{
                    marginTop: "0.3rem",
                    marginBottom: "0.3rem",
                    marginLeft: "0.3rem",
                    borderRight: "1px solid #d4d4d8",
                    background: "#fff",
                    borderTop: "none",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={formData.phoneNumber}
                  onChange={(value, country) => {
                    setFormData({
                      ...formData,
                      dialCode: country ? `+${country.dialCode}` : `+91`,
                      phoneNumber: value || "",
                    });
                  }}
                  maxLength={10}
                  buttonComponent={
                    <CountryFlag dialCode={formData.dialCode} svg />
                  }
                />
              </div>

              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Number of venues*
                </div>
                <select
                  name="numberOfVenues"
                  value={formData.numberOfVenues}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight appearance-none"
                  style={{
                    background: `url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23b2b2b2" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path></svg>') no-repeat right 1rem center/1.5rem`,
                  }}
                >
                  <option value="" disabled hidden>
                    Number of venues
                  </option>
                  <option value="1">1</option>
                  <option value="2-5">2-5</option>
                  <option value="6-15">6-15</option>
                  <option value="16+">16+</option>
                </select>
              </div>
            </div>

            <div className="LeftInputsWrapper">
              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Location*
                </div>
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={formData.location}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300 text-sm font-normal font-['Gilroy-Medium'] tracking-tight"
                />
              </div>

              <div className="w-full md:w-1/2 flex-col justify-start items-start gap-2 flex">
                <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                  Promo code (optional)
                </div>
                <input
                  type="text"
                  name="promoCode"
                  placeholder="Promo code"
                  value={formData.promoCode}
                  onChange={handleChange}
                  className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300 text-sm font-normal font-['Gilroy-Medium'] tracking-tight"
                />
              </div>
            </div>

            <div className="w-full flex-col justify-start items-start gap-2 flex">
              <div className="text-zinc-700 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                Tell us what you are looking for
              </div>
              <select
                name="lookingFor"
                value={formData.lookingFor}
                onChange={handleChange}
                className="px-4 py-3 w-full focus:outline-black  rounded-lg border border-zinc-300  text-sm font-normal font-['Gilroy-Medium'] tracking-tight appearance-none"
                style={{
                  background: `url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23b2b2b2" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path></svg>') no-repeat right 1rem center/1.5rem`,
                }}
              >
                <option value="" disabled hidden>
                  Select
                </option>
                <option value="I want to learn about group reservations">
                  I want to learn about group reservations
                </option>
                <option value="I want to use grouple for a single event">
                  I want to use grouple for a single event
                </option>
                <option value="I’m a current customer with a question">
                  I’m a current customer with a question
                </option>
                <option value="I want to book for a group">
                  I want to book for a group
                </option>
                <option value="Other">Other</option>
              </select>
            </div>

            <button
              type="submit"
              disabled={isSubmitting2}
              className="w-full py-3.5 bg-groupleTheme rounded-[100px] justify-center items-center gap-2.5 flex text-white text-base font-normal font-['Gilroy-SemiBold'] tracking-tight"
            >
              {isSubmitting2 ? "Booking..." : "Book a Demo"}
            </button>
          </form>
        </div>
      </div>

      <div>
        <Logos />
      </div>
      <NewFooter />

      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        style={{ zIndex: "9999" }}
      >
        <div
          className="flex w-full flex-col items-center space-y-4 sm:items-end"
          style={{ marginTop: "4rem" }}
        >
          <Transition
            show={showNotification2}
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
            enterTo="opacity-100 translate-y-0 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0 sm:translate-x-0"
            leaveTo="opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Demo booked successfully!
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowNotification2(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
