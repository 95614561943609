import React from "react";
import "../Styles/Elevate.css";
import elevate from "../images/elevate.png";
import { Link } from "react-router-dom";

const Elevate = () => {
  return (
    <div className="elevate">
      <img src={elevate} alt="" />

      <div className="inner-elevate">
        <h2>The Next-Generation Group Reservation Management Software</h2>
        <Link to="/bookAdemo">
          <button>Book a Demo</button>
        </Link>
      </div>
    </div>
  );
};

export default Elevate;
