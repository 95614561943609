import React, { useEffect } from "react";
import NewNavbar from "../New Components/NewNavbar";
import Questions from "../New Components/Questions";
import NewFooter from "../New Components/Footer";
import Elevate from "../New Components/Elevate";
import Simplify from "../New Components/Simplify";
import Features from "../New Components/Features";
import Hospitality from "../New Components/Hospitality";
import Logos from "../New Components/Logos";
import NewHero from "../New Components/NewHero";
import MobileSimplify from "../New Components/MobileSimplify";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const onBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <>
      <NewNavbar />
      <NewHero />
      <Logos />
      <Features />
      <Hospitality />
      <Simplify />
      <MobileSimplify />
      <Elevate />
      <Questions />
      <NewFooter />
    </>
  );
};

export default LandingPage;
