import React from "react";
import "../Styles/Features.css";
import feature1 from "../images/feature1.png";
import feature2 from "../images/feature2.png";
import feature3 from "../images/feature3.png";
import feature4 from "../images/feature4.png";
import feature5 from "../images/feature5.png";

const Features = () => {
  return (
    <div className="features" id="features">
      <div className="text-feature">
        <h2>
          All the features you need <br />
          <span> to succeed</span>{" "}
        </h2>
      </div>

      {/* first feature */}
      <div className="container-box">
        <div className="left-box">
          <h3>Customise your dedicated microsite</h3>
          <h5>
            Create a group booking website that reflects your brand. Showcase
            photos, venue details, and exclusive packages to attract corporate
            and VIP clients. Enhance your online guest experience with a site
            that’s uniquely yours.
          </h5>
        </div>

        <div className="right-box">
          <img src={feature1} alt="" />
        </div>
      </div>

      {/* second feature */}
      <div className="container-box2 fifthFeature">
        <div className="right-box">
          <img src={feature2} alt="" />
        </div>
        <div className="left-box">
          <h3>Bundle products and services</h3>
          <h5>
            Craft personalised package deals with flexible options with food,
            beverage, entertainment, and premium add-ons. Increase the average
            check size and revenue by up-selling premium offerings tailored to
            your guests preferences.
          </h5>
        </div>
      </div>

      {/* third feature */}
      <div className="container-box">
        <div className="left-box">
          <h3>Realtime booking dashboard</h3>
          <h5>
            Monitor your group sales across multiple products and services. You
            can easily edit reservations, refer to F&B selections, and manage
            customer data—all from one convenient location.
          </h5>
        </div>

        <div className="right-box">
          <img src={feature3} alt="" />
        </div>
      </div>

      {/*  fourth feature */}
      <div className="container-box2">
        <div className="right-box">
          <img src={feature4} alt="" />
        </div>
        <div className="left-box">
          <h3>Dynamic pricing for every occasion</h3>
          <h5>
            Utilise our dynamic pricing mechanism to offer tiered discounts
            based on the party size. Recommend premium food, beverage pairings,
            and unique add-ons to increase the average spend per guest.
          </h5>
        </div>
      </div>

      {/* fifth feature */}
      <div className="container-box fifthFeature">
        <div className="left-box">
          <h3>Personalised concierge service</h3>
          <h5>
            Assign a dedicated Single Point of Contact (SPOC) to elevate the
            guest experience and ensure smooth onsite interactions. Facilitate
            effortless group invitations and RSVPs for a seamless check-in
            process.
          </h5>
        </div>

        <div className="right-box">
          <img src={feature5} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Features;
