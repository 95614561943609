import React, { useEffect, useRef } from "react";
import pontaq from "../images/pontaq copy.png";
import meity from "../images/meity copy.png";
import IA from "../images/IA copy.png";
import nasscom from "../images/nasscom.png";
import wadhwani from "../images/wadhwani copy.png";
import ngis from "../images/ngis copy.png";
import moksha from "../images/moksha.png";
import janjaes from "../images/janjaes.png";
import barbar from "../images/barbar.png";
import pahadi from "../images/pahadi.png";
import startup from "../images/startuplogo.png";
import stpi from "../images/stpi logo.png";
import "../Styles/Questions.css";


export default function Logos() {
 const logoContainerRef = useRef(null);


 useEffect(() => {
   const scrollSpeed = 3;
   const interval = setInterval(() => {
     if (logoContainerRef.current) {
       const { scrollLeft, scrollWidth, clientWidth } = logoContainerRef.current;
       if (scrollLeft >= scrollWidth / 2) {
         logoContainerRef.current.scrollLeft = 0;
       } else {
         logoContainerRef.current.scrollLeft += scrollSpeed;
       }
     }
   }, 20); 


   return () => clearInterval(interval);
 }, []);


 const logos = [
   { src: nasscom, alt: "nasscom", className: "logo-nasscom" },
   { src: wadhwani, alt: "wadhwani", className: "logo-wadhwani" },
   { src: pahadi, alt: "pahadi", className: "logo-pahadi" },
   { src: ngis, alt: "ngis", className: "logo-ngis" },
   { src: IA, alt: "IA", className: "logo-IA" },
   { src: barbar, alt: "barbar", className: "logo-barbar" },
   { src: startup, alt: "startup", className: "logo-startup" },
   { src: stpi, alt: "stpi", className: "logo-stpi" },
   { src: moksha, alt: "moksha", className: "logo-moksha" },
   { src: meity, alt: "meity", className: "logo-meity" },
   { src: pontaq, alt: "pontaq", className: "logo-pontaq" },
   { src: janjaes, alt: "janjaes", className: "logo-janjaes" },
 ];


 return (
   <div className="bg-white">
     <main>
       <div>
         {/* Logo cloud */}
         <div className="bg-white">
           <div className="mx-auto max-w-7xl px-6 py-16 lg:px-6">
             <p className="text-logo">Trusted by World’s Leading Companies</p>
             <div className="logo-container mt-8" ref={logoContainerRef}>
               <div className="logo-slider">
                 {logos.concat(logos).map((logo, index) => (
                   <div className="logo-item" key={index}>
                     <img className={logo.className} src={logo.src} alt={logo.alt} />
                   </div>
                 ))}
               </div>
             </div>
           </div>
         </div>
       </div>
     </main>
   </div>
 );
}





